import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/posizioniAperte.module.scss'

class InformativaCandidati extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Privacy Policy Candidati" description={'Consulta la privacy policy per tutti i candidati in cerca di lavoro.'} />

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}><Light>Privacy policy candidati</Light></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>

                  <h3>INFORMATIVA TRATTAMENTO DATI PERSONALI AI SENSI DEGLI ARTT.  13 E 14 DEL REGOLAMENTO UE 2016/679</h3>
                  <p>Ai sensi dell’art. 13 e 14 del Regolamento UE n. 2016/679 sulla protezione dei dati personali<br />(di seguito il “GDPR” o “Regolamento”), la StarSoftware Srl (di seguito “Starsoftware” o il “titolare”) La informa che i dati personali (di seguito i “dati”), a Lei relativi (di seguito “Interessato”)<br />che ci fornirà o raccolti presso terzi, saranno trattati nel rispetto di quanto previsto dal Regolamento e in conformità all’informativa che segue.</p>

                  <h4>TITOLARE DEL TRATTAMENTO</h4>
                  <p>Il Titolare del trattamento è Starsoftware Srl, con sede legale in Via Verga n. 3/A, 30038, Spinea (VE), Italia,<br />P.Iva 03352380277
                    <ul className="lista_generica lista_competenze">
                     <li>tel. <a href="tel:0039041998062" target="_blank">041/998062</a></li>
                     <li>fax <a href="tel:00390415088091" target="_blank">041/5088091</a></li>
                     <li>email <a href="mailto:info@starsoftware.it" target="_blank"></a>info@starsoftware.it.</li>
                    </ul>
                  </p>

                  <h4>FINALITÀ E BASE GIURIDICA DEL TRATTAMENTO</h4>
                  <p>La informiamo che i Suoi Dati personali saranno trattati, tramite l’utilizzo di strumenti e procedure idonei a garantirne la massima sicurezza e riservatezza, mediante archivi e supporti cartacei, con l’ausilio di supporti digitali, mezzi informatici e telematici, per le seguenti finalità:
                    <ul className="lista_generica lista_competenze">
                     <li>raccolta dei dati dei candidati per fini di ricerca del personale e eventuale finalizzazione di un contratto.</li>
                     <li>archiviazione dei curricula per esigenze future di ricerca del personale;</li>
                    </ul>
                  </p>
                  <p>I dati saranno trattati ai sensi dell’art. 6 c. lett. a) “consenso espresso dall’interessato” e ai sensi del art. 6 c.1 lett. b) “esecuzione di misure precontrattuali adottate su richiesta dell’Interessato”. Eventuali dati particolari verranno trattati ai sensi dell’art. 9 c.2 lett. b).</p>
                  <p>Nel valutare quanto sopra al fine di fornirci il Suo consenso al trattamento La preghiamo di tener presente che fra le informazioni personali oggetto del medesimo e da Lei fornite spontaneamente vi possono essere anche alcuni dati definiti “particolari” dall’art. 9 del Regolamento (ad esempio appartenenza a categorie protette). Tali dati se non espressamente non sono richiesti per le finalità di cui sopra.</p>

                  <h4>MODALITÀ DI RACCOLTA E NATURA DEI DATI</h4>
                  <p>I Dati sono raccolti tramite:
                    <ul className="lista_generica lista_competenze">
                     <li>l’invio spontaneo di curriculum effettuato da parte del candidato all’indirizzo e-mail curriculum@starsoftware.it o tramite i canali selezionati indicati sul sito web del titolare curriculum@starsoftware.it</li>
                     <li>la consultazione di banche dati specializzate, all’interno delle quali il candidato ha reso disponibile il proprio curriculum al fine di renderlo consultabile da parte di aziende interessate</li>
                     <li>agenzie di recruitment e società specializzate nella ricerca e selezione del personale</li>
                     <li>qualsiasi altra modalità attraverso la quale i curricula dei candidati vengano resi disponibili alla Società</li>
                    </ul>
                  </p>
                  <p>Costituiscono oggetto di trattamento i dati personali (anagrafici e di contatto) contenuti nel curriculum o nella mail accompagnatoria e costituiti da altri Dati da Lei spontaneamente forniti per meglio qualificare la sua candidatura (quali ad esempio, carriera scolastica, immagini, situazione professionale, competenze e conoscenze specifiche, ecc.…).</p>
                  <p>Il trattamento è effettuato anche con l’ausilio di mezzi elettronici o comunque automatizzati e può consistere in qualunque operazione tra quelle indicate all’art. 4, comma 1, punto.2 del Regolamento.</p>
                  <p>Il conferimento dei dati è facoltativo tuttavia il rifiuto a conferire alcuni dati potrebbe comportare l’impossibilità di partecipare alle suddette procedure di selezione o ad una non corretta valutazione delle Sue qualità professionali.</p>

                  <h4>PERIODO DI CONSERVAZIONE DEI DATI</h4>
                  <p>I dati verranno trattati per il tempo necessario al contatto e al successivo colloquio ovvero per l’archiviazione dei curricula per esigenze future di ricerca del personale per un tempo massimo di 36 mesi trascorso il quale i Suoi dati verranno distrutti nel rispetto delle prerogative a salvaguardia dei suoi diritti.</p>

                  <h4>DESTINATARI O CATEGORIE DI DESTINATARI</h4>
                  <p>I dati raccolti non saranno soggetti a diffusione e/o a trasferimento verso Paesi terzi. I dati verranno custoditi presso il titolare e verranno trattati per le finalità descritte sopra solo da personale autorizzato.</p>

                  <h4>DIRITTI DELL’INTERESSATO</h4>
                  <p>All’Interessato sono riconosciuti i diritti, nei casi e nei limiti previsti dal Regolamento di cui agli art. da 15 a 20. A titolo esemplificativo, ciascun interessato potrà:
                    <ul className="lista_generica lista_competenze">
                     <li>ottenere la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano</li>
                     <li>qualora un trattamento sia in corso, ottenere l’accesso ai dati personali e alle informazioni relative al trattamento nonché richiedere una copia dei dati personali</li>
                     <li>ottenere la rettifica dei dati personali inesatti e l’integrazione dei dati personali incompleti</li>
                     <li>ottenere, qualora sussista una delle condizioni previste dall’art. 17 del GDPR, la cancellazione dei dati personali che lo riguardano</li>
                     <li>ottenere, nei casi previsti dall’art. 18 del GDPR, la limitazione del trattamento</li>
                     <li>ricevere i dati personali che lo riguardano in un formato strutturato, di uso comune e leggibile da dispositivo automatico e richiedere la loro trasmissione ad un altro titolare, se tecnicamente fattibile</li>
                    </ul>
                    Al fine di garantire che i diritti sopra citati vengano esercitati dall’Interessato e non da terzi non autorizzati, il titolare potrà richiedere allo stesso di fornire eventuali ulteriori informazioni necessarie allo scopo.
                  </p>

                </div>
                <div className={`offset-md-5 col-md-7 no-padding ${style.page__content__fullScreenSlide__arrow}`}></div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default InformativaCandidati
